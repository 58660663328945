import { FC } from 'react';
import { Box, Breadcrumbs, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'src/app/reduxCustomHooks';
import { shallowEqual } from 'react-redux';

type previousPathType = {
  title: string;
  href: string;
  index?: number;
};

const previousPath = ({ title, href, index }: previousPathType) => (
  <Link to={href} key={index} style={{ textDecoration: 'none' }}>
    <Typography
      sx={{ cursor: 'pointer' }}
      color="primary"
      fontSize={13}
      fontWeight={400}
    >
      {title}
    </Typography>
  </Link>
);

const lastPath = ({ title, href, index }: previousPathType) => (
  <Typography key={index} fontSize={13} fontWeight={400}>
    {title}
  </Typography>
);

const BreadCrumbs: FC = () => {
  const { t } = useTranslation(),
    { pathname } = useLocation(),
    firstName = useAppSelector(
      (state) => state.auth.user?.firstName,
      shallowEqual
    );

  let showItems = [{ title: t('home'), href: '/' }];
  if (pathname === '/') {
    showItems = [{ title: t('welcome') + ' ' + firstName, href: '/' }];
  } else {
    let paths = '';
    pathname
      .replace('/', '')
      .split('/')
      // eslint-disable-next-line array-callback-return
      .map((path) => {
        if (path !== 'home') {
          paths += `/${path}`;
          showItems.push({ title: t(path), href: paths });
        }
      });
  }

  return (
    <Breadcrumbs
      separator={
        <Box
          sx={{
            color: ({ palette }) => palette.primary.main,
            fontSize: '13px',
            fontWeight: '400',
          }}
        >
          \
        </Box>
      }
      aria-label="breadcrumb"
      sx={{ width: '100%', display: { xs: 'none', md: 'block' } }}
    >
      {showItems.map(({ title, href }, index) => {
        if (showItems.length === 1) {
          return lastPath({ title, href, index });
        } else {
          if (index === showItems.length - 1) {
            return lastPath({ title, href, index });
          } else {
            return previousPath({ title, href, index });
          }
        }
      })}
    </Breadcrumbs>
  );
};

export default BreadCrumbs;
