import type { FC } from 'react';
import { useMemo } from 'react';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import BeatLoader from 'react-spinners/BeatLoader';
import { useLocation } from 'react-router';

const PageLoading: FC = () => {
  const theme = useTheme(),
    { pathname } = useLocation();

  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isEditPage = useMemo(() => pathname.includes('edit'), [pathname]);

  const contentBoxMinHeight = useMemo(() => {
    let result = '0';
    if (isEditPage) {
      result = `calc(100vh - ${isMd ? 67.5 + 64 : 56 + 32}px)`;
    } else {
      result = `calc(100vh - ${isMd ? 87.5 + 64 : 67 + 32}px)`;
    }
    return result;
  }, [isEditPage, isMd]);

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        height: contentBoxMinHeight,
      }}
    >
      <BeatLoader color={theme.palette.primary.main} loading size={50} />
    </Stack>
  );
};

export default PageLoading;
