import { FC, Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import DashboardTemplate from 'src/components/templates/DashboardTemplate';
import EditPageHeader from 'src/components/organisms/editPage/header/EditPageHeader';
import PageLoading from 'src/components/atoms/PageLoading';

const Dashboard = lazy(() => import('src/pages/dashboard'));
const Login = lazy(() => import('src/pages/login'));
const ForgotPassword = lazy(() => import('src/pages/forgotPassword'));
const NotFound = lazy(() => import('src/pages/notfound'));
const CreateWorkspace = lazy(() => import('src/pages/createWorkspace'));
const History = lazy(() => import('src/pages/history'));
const CreateTasks = lazy(() => import('src/pages/createTasks'));
const Profile = lazy(() => import('src/pages/profile/profile'));
const AllTasks = lazy(() => import('src/pages/profile/allTasks'));
const Users = lazy(() => import('src/pages/profile/users/users'));
const Edit = lazy(() => import('src/pages/edit'));
const UsersHistory = lazy(() => import('src/pages/profile/users/history'));
const UserFiles = lazy(() => import('src/pages/userFiles'));

const getPage = (
  TemplateComponent: FC,
  PageComponent: FC,
  templateProps?: any
) => (
  <TemplateComponent {...templateProps}>
    <Suspense fallback={<PageLoading />}>
      <PageComponent />
    </Suspense>
  </TemplateComponent>
);

const Router: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgotPassword/*" element={<ForgotPassword />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={getPage(DashboardTemplate, Dashboard)} />
          <Route
            path="/createTasks"
            element={getPage(DashboardTemplate, CreateTasks)}
          />
          <Route
            path="/createWorkspace"
            element={getPage(DashboardTemplate, CreateWorkspace)}
          />
          <Route
            path="/edit/:id"
            element={getPage(DashboardTemplate, Edit, {
              navbarComponent: <EditPageHeader />,
            })}
          />
          <Route
            path="/history"
            element={getPage(DashboardTemplate, History)}
          />
          <Route
            path="/profile"
            element={getPage(DashboardTemplate, Profile)}
          />
          <Route
            path="/profile/users"
            element={getPage(DashboardTemplate, Users)}
          />
          <Route
            path="/profile/allTasks"
            element={getPage(DashboardTemplate, AllTasks)}
          />
          <Route
            path="/profile"
            element={getPage(DashboardTemplate, Profile)}
          />
          <Route
            path="/profile/users/history"
            element={getPage(DashboardTemplate, UsersHistory)}
          />
          <Route
            path="/userFiles"
            element={getPage(DashboardTemplate, UserFiles)}
          />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
