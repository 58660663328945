import { createTheme, PaletteColor, PaletteColorOptions } from '@mui/material';

const customBreakpoints = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1285,
  xl: 1536,
};

const BORDER_RADIUS = 10;

declare module '@mui/material/styles' {
  interface Palette {
    customGrey: PaletteColor & {
      dark2: string;
      divider: string;
      background: string;
      subListBackground: string;
      sidebarSubListSelectedBackground: string;
      sidebarSubListSelectedBorder: string;
      ultraLight: string;
      grey1: string;
    };
  }
  interface PaletteOptions {
    customGrey: PaletteColorOptions & {
      dark2: string;
      divider: string;
      background: string;
      subListBackground: string;
      sidebarSubListSelectedBackground: string;
      sidebarSubListSelectedBorder: string;
      ultraLight: string;
      grey1: string;
    };
  }
}

const theme = createTheme({
  breakpoints: {
    values: customBreakpoints,
  },
  palette: {
    mode: 'dark',
    primary: { main: '#02CA8E', light: '#02E09E' },
    error: { main: '#F83232', light: '#FF7373' },
    customGrey: {
      dark: '#353A42',
      dark2: '#3C434D',
      main: '#444D58',
      light: '#4F5660',
      divider: 'linear-gradient(90deg, #353A42, white, #353A42)',
      background: '#282C31',
      subListBackground: '#697382',
      sidebarSubListSelectedBackground: '#848D9A',
      sidebarSubListSelectedBorder: '#788495',
      ultraLight: '#ffffffbf',
      grey1: '#B4B3B3',
    },
    warning: {
      main: '#FFC700',
    },
    info: {
      main: '#00C2FF',
    },
    background: {
      paper: '#353A42',
    },
  },
  typography: {
    button: {
      textTransform: 'capitalize',
    },
  },
  shape: {
    borderRadius: BORDER_RADIUS,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          color: 'white',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiTextField: {
      defaultProps: {
        // variant: 'filled',
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: BORDER_RADIUS,
          overflow: 'hidden',
        },
      },
    },
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave',
      },
    },
    MuiContainer: {
      defaultProps: { maxWidth: 'lg' },
    },
  },
});

export default theme;
