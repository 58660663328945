import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const SignOutSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      sx={{
        '& path': {
          fill: (theme) => theme.palette.error.light,
        },
      }}
      {...props}
    >
      <path d="M14.9998 13.0002C14.4469 13.0002 13.9999 13.4482 13.9999 14.0001V18.0002C13.9999 18.5511 13.5519 19.0001 12.9998 19.0001H9.99984V4.00027C9.99984 3.14627 9.45584 2.38328 8.63791 2.09928L8.34183 2.00022H12.9998C13.5519 2.00022 13.9999 2.44919 13.9999 3.00034V6.00031C13.9999 6.55218 14.4469 7.00024 14.9998 7.00024C15.5528 7.00024 15.9998 6.55218 15.9998 6.00031V3.00034C15.9998 1.34636 14.6538 0.000366211 12.9998 0.000366211H2.24998C2.21189 0.000366211 2.18003 0.0173949 2.14304 0.0223386C2.09489 0.0183104 2.04893 0.000366211 2.00004 0.000366211C0.897025 0.000366211 0 0.897208 0 2.00022V20C0 20.854 0.544001 21.617 1.36192 21.901L7.37999 23.9071C7.58397 23.9701 7.78685 24.0001 7.99998 24.0001C9.103 24.0001 9.99984 23.1031 9.99984 22.0001V21.0002H12.9998C14.6538 21.0002 15.9998 19.6542 15.9998 18.0002V14.0001C15.9998 13.4482 15.5528 13.0002 14.9998 13.0002Z" />
      <path d="M23.707 9.29317L19.7069 5.29327C19.4211 5.00726 18.991 4.9212 18.6171 5.07611C18.2441 5.2312 18 5.59612 18 6.00023V9.0002H14.0001C13.4481 9.0002 13 9.44807 13 10.0001C13 10.5522 13.4481 11.0001 14.0001 11.0001H18V14C18 14.4041 18.2441 14.7691 18.6171 14.9242C18.991 15.0791 19.4211 14.993 19.7069 14.7072L23.707 10.7071C24.0979 10.3162 24.0979 9.6841 23.707 9.29317Z" />
    </SvgIcon>
  );
};

export default SignOutSvg;
