import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/app/services/baseQuery';
export const api = createApi({
  baseQuery: baseQuery,
  tagTypes: [],
  endpoints: (build) => ({
    usersControllerCreate: build.mutation<
      UsersControllerCreateApiResponse,
      UsersControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users`,
        method: 'POST',
        body: queryArg.userCreateDto,
      }),
    }),
    usersControllerUpdate: build.mutation<
      UsersControllerUpdateApiResponse,
      UsersControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users`,
        method: 'PUT',
        body: queryArg.adminUserUpdateDto,
      }),
    }),
    usersControllerFind: build.query<
      UsersControllerFindApiResponse,
      UsersControllerFindApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users`,
        params: {
          username: queryArg.username,
          firstName: queryArg.firstName,
          lastName: queryArg.lastName,
          email: queryArg.email,
          documentCount: queryArg.documentCount,
          company: queryArg.company,
          isManager: queryArg.isManager,
        },
      }),
    }),
    usersControllerDelete: build.mutation<
      UsersControllerDeleteApiResponse,
      UsersControllerDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users`,
        method: 'DELETE',
        body: queryArg.usernameDto,
      }),
    }),
    usersControllerCreateCompanyUser: build.mutation<
      UsersControllerCreateCompanyUserApiResponse,
      UsersControllerCreateCompanyUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/company/users`,
        method: 'POST',
        body: queryArg.companyUserCreateDto,
      }),
    }),
    usersControllerFindCompanyUsers: build.query<
      UsersControllerFindCompanyUsersApiResponse,
      UsersControllerFindCompanyUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/company/users`,
        params: {
          username: queryArg.username,
          firstName: queryArg.firstName,
          lastName: queryArg.lastName,
          email: queryArg.email,
          documentCount: queryArg.documentCount,
          isManager: queryArg.isManager,
        },
      }),
    }),
    usersControllerUserProfile: build.query<
      UsersControllerUserProfileApiResponse,
      UsersControllerUserProfileApiArg
    >({
      query: () => ({ url: `/api/v1/users/profile` }),
    }),
    usersControllerUpdateUserProfile: build.mutation<
      UsersControllerUpdateUserProfileApiResponse,
      UsersControllerUpdateUserProfileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/profile`,
        method: 'POST',
        body: queryArg.userUpdateDto,
      }),
    }),
    usersControllerSuspend: build.mutation<
      UsersControllerSuspendApiResponse,
      UsersControllerSuspendApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/suspend`,
        method: 'PUT',
        body: queryArg.usernameDto,
      }),
    }),
    usersControllerActivate: build.mutation<
      UsersControllerActivateApiResponse,
      UsersControllerActivateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/activate`,
        method: 'POST',
        body: queryArg.usernameDto,
      }),
    }),
    usersControllerAssignRole: build.mutation<
      UsersControllerAssignRoleApiResponse,
      UsersControllerAssignRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/role`,
        method: 'POST',
        body: queryArg.userRoleDto,
      }),
    }),
    usersControllerCheckPermission: build.mutation<
      UsersControllerCheckPermissionApiResponse,
      UsersControllerCheckPermissionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/checkPermission`,
        method: 'POST',
        body: queryArg.checkPermissionDto,
      }),
    }),
    usersControllerMailResetPassword: build.mutation<
      UsersControllerMailResetPasswordApiResponse,
      UsersControllerMailResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/mailResetPassword`,
        method: 'POST',
        body: queryArg.mailResetPasswordDto,
      }),
    }),
    usersControllerCheckResetPasswordCred: build.query<
      UsersControllerCheckResetPasswordCredApiResponse,
      UsersControllerCheckResetPasswordCredApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/checkResetPasswordCred`,
        params: { resetCode: queryArg.resetCode, email: queryArg.email },
      }),
    }),
    usersControllerResetPassword: build.mutation<
      UsersControllerResetPasswordApiResponse,
      UsersControllerResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/resetPassword`,
        method: 'POST',
        body: queryArg.changePasswordDto,
        params: { resetCode: queryArg.resetCode, email: queryArg.email },
      }),
    }),
    usersControllerChangePassword: build.mutation<
      UsersControllerChangePasswordApiResponse,
      UsersControllerChangePasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/changePassword`,
        method: 'POST',
        body: queryArg.changePasswordDto,
      }),
    }),
    usersControllerGetCompanies: build.query<
      UsersControllerGetCompaniesApiResponse,
      UsersControllerGetCompaniesApiArg
    >({
      query: () => ({ url: `/api/v1/users/company` }),
    }),
    authControllerLogin: build.mutation<
      AuthControllerLoginApiResponse,
      AuthControllerLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth`,
        method: 'POST',
        body: queryArg.loginDto,
      }),
    }),
    authControllerSignup: build.mutation<
      AuthControllerSignupApiResponse,
      AuthControllerSignupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth/signup`,
        method: 'POST',
        body: queryArg.userCreateDto,
      }),
    }),
    rolesControllerReadPermissions: build.query<
      RolesControllerReadPermissionsApiResponse,
      RolesControllerReadPermissionsApiArg
    >({
      query: () => ({ url: `/api/v1/roles/permissions` }),
    }),
    rolesControllerCreateRole: build.mutation<
      RolesControllerCreateRoleApiResponse,
      RolesControllerCreateRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/roles`,
        method: 'POST',
        body: queryArg.roleDto,
      }),
    }),
    rolesControllerUpdateRole: build.mutation<
      RolesControllerUpdateRoleApiResponse,
      RolesControllerUpdateRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/roles`,
        method: 'PUT',
        body: queryArg.updateRoleDto,
      }),
    }),
    rolesControllerFindRole: build.query<
      RolesControllerFindRoleApiResponse,
      RolesControllerFindRoleApiArg
    >({
      query: () => ({ url: `/api/v1/roles` }),
    }),
    rolesControllerDeleteRole: build.mutation<
      RolesControllerDeleteRoleApiResponse,
      RolesControllerDeleteRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/roles`,
        method: 'DELETE',
        body: queryArg.roleIdDto,
      }),
    }),
    rolesControllerReadRole: build.query<
      RolesControllerReadRoleApiResponse,
      RolesControllerReadRoleApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/roles/${queryArg.roleId}` }),
    }),
    mediaControllerUploadDocument: build.mutation<
      MediaControllerUploadDocumentApiResponse,
      MediaControllerUploadDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/media`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    mediaControllerGetTaskFileUrl: build.query<
      MediaControllerGetTaskFileUrlApiResponse,
      MediaControllerGetTaskFileUrlApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/media/taskFile/${queryArg.taskId}/url`,
      }),
    }),
    mediaControllerGetTaskPages: build.query<
      MediaControllerGetTaskPagesApiResponse,
      MediaControllerGetTaskPagesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/media/taskPages/${queryArg.taskId}`,
      }),
    }),
    mediaControllerGetTaskOutFileUrl: build.query<
      MediaControllerGetTaskOutFileUrlApiResponse,
      MediaControllerGetTaskOutFileUrlApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/media/taskOutFile/${queryArg.taskId}/url`,
      }),
    }),
    tasksControllerGetTask: build.query<
      TasksControllerGetTaskApiResponse,
      TasksControllerGetTaskApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/tasks/task/${queryArg.id}` }),
    }),
    tasksControllerGetWorkspaceTasks: build.query<
      TasksControllerGetWorkspaceTasksApiResponse,
      TasksControllerGetWorkspaceTasksApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/tasks`,
        params: {
          pageNumber: queryArg.pageNumber,
          workspaceId: queryArg.workspaceId,
        },
      }),
    }),
    tasksControllerGetWorkspaces: build.query<
      TasksControllerGetWorkspacesApiResponse,
      TasksControllerGetWorkspacesApiArg
    >({
      query: () => ({ url: `/api/v1/tasks/workspaces` }),
    }),
    tasksControllerRemove: build.mutation<
      TasksControllerRemoveApiResponse,
      TasksControllerRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/remove`,
        method: 'PATCH',
        body: queryArg.idDto,
      }),
    }),
    tasksControllerDelete: build.mutation<
      TasksControllerDeleteApiResponse,
      TasksControllerDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks`,
        method: 'DELETE',
        body: queryArg.idDto,
      }),
    }),
    tasksControllerDeleteCompanyTasks: build.mutation<
      TasksControllerDeleteCompanyTasksApiResponse,
      TasksControllerDeleteCompanyTasksApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/delete-company-tasks`,
        method: 'DELETE',
        params: { id: queryArg.id },
      }),
    }),
    tasksControllerRemoveMany: build.mutation<
      TasksControllerRemoveManyApiResponse,
      TasksControllerRemoveManyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/remove-many`,
        method: 'PATCH',
        body: queryArg.idsDto,
      }),
    }),
    tasksControllerCreateWorkspace: build.mutation<
      TasksControllerCreateWorkspaceApiResponse,
      TasksControllerCreateWorkspaceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/workspace`,
        method: 'POST',
        body: queryArg.workspaceDto,
      }),
    }),
    tasksControllerDeleteWorkspace: build.mutation<
      TasksControllerDeleteWorkspaceApiResponse,
      TasksControllerDeleteWorkspaceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/workspace`,
        method: 'DELETE',
        body: queryArg.idDto,
      }),
    }),
    tasksControllerGetCompanyTasks: build.query<
      TasksControllerGetCompanyTasksApiResponse,
      TasksControllerGetCompanyTasksApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/company-tasks`,
        params: { companyId: queryArg.companyId },
      }),
    }),
    tasksControllerGetCompanyWorkspaces: build.query<
      TasksControllerGetCompanyWorkspacesApiResponse,
      TasksControllerGetCompanyWorkspacesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/company-workspaces`,
        params: { companyId: queryArg.companyId },
      }),
    }),
    tasksControllerAddItem: build.mutation<
      TasksControllerAddItemApiResponse,
      TasksControllerAddItemApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/item`,
        method: 'POST',
        body: queryArg.addItemDto,
      }),
    }),
    tasksControllerDeleteItem: build.mutation<
      TasksControllerDeleteItemApiResponse,
      TasksControllerDeleteItemApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/item`,
        method: 'DELETE',
        body: queryArg.idDto,
      }),
    }),
    tasksControllerAddCustomBox: build.mutation<
      TasksControllerAddCustomBoxApiResponse,
      TasksControllerAddCustomBoxApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/custom-box`,
        method: 'POST',
        body: queryArg.addCustomBoxDto,
      }),
    }),
    tasksControllerGetItems: build.query<
      TasksControllerGetItemsApiResponse,
      TasksControllerGetItemsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/${queryArg.taskId}/${queryArg.pageNumber}/items`,
      }),
    }),
    tasksControllerUpdateBox: build.mutation<
      TasksControllerUpdateBoxApiResponse,
      TasksControllerUpdateBoxApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/box`,
        method: 'PUT',
        body: queryArg.updateBoxDto,
      }),
    }),
    tasksControllerChangeRedactState: build.mutation<
      TasksControllerChangeRedactStateApiResponse,
      TasksControllerChangeRedactStateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/item-redact-state`,
        method: 'PUT',
        body: queryArg.changeRedactStateDto,
      }),
    }),
    tasksControllerGetLastModifiedTask: build.query<
      TasksControllerGetLastModifiedTaskApiResponse,
      TasksControllerGetLastModifiedTaskApiArg
    >({
      query: () => ({ url: `/api/v1/tasks/last-modified-task` }),
    }),
    tasksControllerGetUsage: build.query<
      TasksControllerGetUsageApiResponse,
      TasksControllerGetUsageApiArg
    >({
      query: () => ({ url: `/api/v1/tasks/usage` }),
    }),
    tasksControllerChangeVerify: build.mutation<
      TasksControllerChangeVerifyApiResponse,
      TasksControllerChangeVerifyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/verify`,
        method: 'PUT',
        body: queryArg.verifyDto,
      }),
    }),
    tasksControllerGenerateOutput: build.mutation<
      TasksControllerGenerateOutputApiResponse,
      TasksControllerGenerateOutputApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/generate-output`,
        method: 'PUT',
        body: queryArg.idDto,
      }),
    }),
    tasksControllerGetTasksState: build.query<
      TasksControllerGetTasksStateApiResponse,
      TasksControllerGetTasksStateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/tasks-state`,
        params: { ids: queryArg.ids },
      }),
    }),
    tasksControllerGetTaskOutputState: build.query<
      TasksControllerGetTaskOutputStateApiResponse,
      TasksControllerGetTaskOutputStateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/task-output-state`,
        params: { id: queryArg.id },
      }),
    }),
    tasksControllerSearch: build.query<
      TasksControllerSearchApiResponse,
      TasksControllerSearchApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/search`,
        params: { taskId: queryArg.taskId, text: queryArg.text },
      }),
    }),
    tasksControllerGetPagesItems: build.query<
      TasksControllerGetPagesItemsApiResponse,
      TasksControllerGetPagesItemsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/${queryArg.taskId}/${queryArg.start}/${queryArg.end}/items`,
      }),
    }),
    tasksControllerGetTaskCurrentPage: build.query<
      TasksControllerGetTaskCurrentPageApiResponse,
      TasksControllerGetTaskCurrentPageApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/currentPage/${queryArg.taskId}`,
      }),
    }),
    tasksControllerSetTaskCurrentPage: build.mutation<
      TasksControllerSetTaskCurrentPageApiResponse,
      TasksControllerSetTaskCurrentPageApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/currentPage`,
        method: 'PATCH',
        body: queryArg.setTaskCurrentPageDto,
      }),
    }),
    tasksInternalControllerGetPdf2ImgTask: build.mutation<
      TasksInternalControllerGetPdf2ImgTaskApiResponse,
      TasksInternalControllerGetPdf2ImgTaskApiArg
    >({
      query: () => ({ url: `/api/v1/tasks/internal/pdf2img`, method: 'POST' }),
    }),
    tasksInternalControllerSetPdf2ImgTaskResult: build.mutation<
      TasksInternalControllerSetPdf2ImgTaskResultApiResponse,
      TasksInternalControllerSetPdf2ImgTaskResultApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/internal/pdf2img/result`,
        method: 'POST',
        body: queryArg.pdf2ImgTaskResultDto,
      }),
    }),
    tasksInternalControllerGetGeneratingTasks: build.mutation<
      TasksInternalControllerGetGeneratingTasksApiResponse,
      TasksInternalControllerGetGeneratingTasksApiArg
    >({
      query: () => ({
        url: `/api/v1/tasks/internal/get-generating-task`,
        method: 'POST',
      }),
    }),
    tasksInternalControllerSetGeneratingTasks: build.mutation<
      TasksInternalControllerSetGeneratingTasksApiResponse,
      TasksInternalControllerSetGeneratingTasksApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/internal/set-generating-task-result`,
        method: 'POST',
        body: queryArg.generatingResultDto,
      }),
    }),
  }),
});
export type UsersControllerCreateApiResponse = /** status 201  */ UserRo;
export type UsersControllerCreateApiArg = {
  userCreateDto: UserCreateDto;
};
export type UsersControllerUpdateApiResponse = unknown;
export type UsersControllerUpdateApiArg = {
  adminUserUpdateDto: AdminUserUpdateDto;
};
export type UsersControllerFindApiResponse = /** status 200  */ UsersRo;
export type UsersControllerFindApiArg = {
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  documentCount?: number;
  company?: string;
  isManager?: boolean;
};
export type UsersControllerDeleteApiResponse = unknown;
export type UsersControllerDeleteApiArg = {
  usernameDto: UsernameDto;
};
export type UsersControllerCreateCompanyUserApiResponse =
  /** status 201  */ UserRo;
export type UsersControllerCreateCompanyUserApiArg = {
  companyUserCreateDto: CompanyUserCreateDto;
};
export type UsersControllerFindCompanyUsersApiResponse =
  /** status 200  */ UsersRo;
export type UsersControllerFindCompanyUsersApiArg = {
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  documentCount?: number;
  isManager?: boolean;
};
export type UsersControllerUserProfileApiResponse = /** status 200  */ UserRo;
export type UsersControllerUserProfileApiArg = void;
export type UsersControllerUpdateUserProfileApiResponse =
  /** status 200  */ UserRo;
export type UsersControllerUpdateUserProfileApiArg = {
  userUpdateDto: UserUpdateDto;
};
export type UsersControllerSuspendApiResponse = unknown;
export type UsersControllerSuspendApiArg = {
  usernameDto: UsernameDto;
};
export type UsersControllerActivateApiResponse = unknown;
export type UsersControllerActivateApiArg = {
  usernameDto: UsernameDto;
};
export type UsersControllerAssignRoleApiResponse = unknown;
export type UsersControllerAssignRoleApiArg = {
  userRoleDto: UserRoleDto;
};
export type UsersControllerCheckPermissionApiResponse = unknown;
export type UsersControllerCheckPermissionApiArg = {
  checkPermissionDto: CheckPermissionDto;
};
export type UsersControllerMailResetPasswordApiResponse = unknown;
export type UsersControllerMailResetPasswordApiArg = {
  mailResetPasswordDto: MailResetPasswordDto;
};
export type UsersControllerCheckResetPasswordCredApiResponse = unknown;
export type UsersControllerCheckResetPasswordCredApiArg = {
  resetCode: number;
  email: string;
};
export type UsersControllerResetPasswordApiResponse = unknown;
export type UsersControllerResetPasswordApiArg = {
  resetCode: number;
  email: string;
  changePasswordDto: ChangePasswordDto;
};
export type UsersControllerChangePasswordApiResponse = unknown;
export type UsersControllerChangePasswordApiArg = {
  changePasswordDto: ChangePasswordDto;
};
export type UsersControllerGetCompaniesApiResponse =
  /** status 200  */ CompaniesRo;
export type UsersControllerGetCompaniesApiArg = void;
export type AuthControllerLoginApiResponse = /** status 200  */ LoginRo;
export type AuthControllerLoginApiArg = {
  loginDto: LoginDto;
};
export type AuthControllerSignupApiResponse = /** status 201  */ LoginRo;
export type AuthControllerSignupApiArg = {
  userCreateDto: UserCreateDto;
};
export type RolesControllerReadPermissionsApiResponse =
  /** status 200  */ PermissionViewRo[];
export type RolesControllerReadPermissionsApiArg = void;
export type RolesControllerCreateRoleApiResponse = /** status 201  */ RoleRo;
export type RolesControllerCreateRoleApiArg = {
  roleDto: RoleDto;
};
export type RolesControllerUpdateRoleApiResponse = unknown;
export type RolesControllerUpdateRoleApiArg = {
  updateRoleDto: UpdateRoleDto;
};
export type RolesControllerFindRoleApiResponse = /** status 200  */ RolesRo;
export type RolesControllerFindRoleApiArg = void;
export type RolesControllerDeleteRoleApiResponse = unknown;
export type RolesControllerDeleteRoleApiArg = {
  roleIdDto: RoleIdDto;
};
export type RolesControllerReadRoleApiResponse = /** status 200  */ RoleRo;
export type RolesControllerReadRoleApiArg = {
  roleId: string;
};
export type MediaControllerUploadDocumentApiResponse =
  /** status 200  */
  IdRo | /** status 201  */ IdRo;
export type MediaControllerUploadDocumentApiArg = {
  body: {
    file: Blob;
    pagesRanges: PagesRangeDto[];
    workspaceName?: string;
    description?: string;
    setting?: SettingDto;
  };
};
export type MediaControllerGetTaskFileUrlApiResponse = /** status 200  */ UrlRo;
export type MediaControllerGetTaskFileUrlApiArg = {
  taskId: string;
};
export type MediaControllerGetTaskPagesApiResponse =
  /** status 200  */ PageRo[];
export type MediaControllerGetTaskPagesApiArg = {
  taskId: string;
};
export type MediaControllerGetTaskOutFileUrlApiResponse =
  /** status 200  */ UrlRo;
export type MediaControllerGetTaskOutFileUrlApiArg = {
  taskId: string;
};
export type TasksControllerGetTaskApiResponse = /** status 200  */ TaskRo;
export type TasksControllerGetTaskApiArg = {
  id: string;
};
export type TasksControllerGetWorkspaceTasksApiResponse =
  /** status 200  */ TasksRo;
export type TasksControllerGetWorkspaceTasksApiArg = {
  pageNumber?: string;
  workspaceId?: string;
};
export type TasksControllerGetWorkspacesApiResponse =
  /** status 200  */ WorkspacesRo;
export type TasksControllerGetWorkspacesApiArg = void;
export type TasksControllerRemoveApiResponse = unknown;
export type TasksControllerRemoveApiArg = {
  idDto: IdDto;
};
export type TasksControllerDeleteApiResponse = unknown;
export type TasksControllerDeleteApiArg = {
  idDto: IdDto;
};
export type TasksControllerDeleteCompanyTasksApiResponse = unknown;
export type TasksControllerDeleteCompanyTasksApiArg = {
  id: string;
};
export type TasksControllerRemoveManyApiResponse = unknown;
export type TasksControllerRemoveManyApiArg = {
  idsDto: IdsDto;
};
export type TasksControllerCreateWorkspaceApiResponse = /** status 200  */ IdRo;
export type TasksControllerCreateWorkspaceApiArg = {
  workspaceDto: WorkspaceDto;
};
export type TasksControllerDeleteWorkspaceApiResponse = unknown;
export type TasksControllerDeleteWorkspaceApiArg = {
  idDto: IdDto;
};
export type TasksControllerGetCompanyTasksApiResponse =
  /** status 200  */ TasksRo;
export type TasksControllerGetCompanyTasksApiArg = {
  companyId?: string;
};
export type TasksControllerGetCompanyWorkspacesApiResponse =
  /** status 200  */ WorkspacesRo;
export type TasksControllerGetCompanyWorkspacesApiArg = {
  companyId?: string;
};
export type TasksControllerAddItemApiResponse = /** status 200  */ ItemRo;
export type TasksControllerAddItemApiArg = {
  addItemDto: AddItemDto;
};
export type TasksControllerDeleteItemApiResponse = /** status 200  */ IdDto;
export type TasksControllerDeleteItemApiArg = {
  idDto: IdDto;
};
export type TasksControllerAddCustomBoxApiResponse = /** status 200  */ ItemRo;
export type TasksControllerAddCustomBoxApiArg = {
  addCustomBoxDto: AddCustomBoxDto;
};
export type TasksControllerGetItemsApiResponse = /** status 200  */ ItemsRo;
export type TasksControllerGetItemsApiArg = {
  taskId: string;
  pageNumber: number;
};
export type TasksControllerUpdateBoxApiResponse = unknown;
export type TasksControllerUpdateBoxApiArg = {
  updateBoxDto: UpdateBoxDto;
};
export type TasksControllerChangeRedactStateApiResponse = unknown;
export type TasksControllerChangeRedactStateApiArg = {
  changeRedactStateDto: ChangeRedactStateDto;
};
export type TasksControllerGetLastModifiedTaskApiResponse =
  /** status 200  */ TaskRo;
export type TasksControllerGetLastModifiedTaskApiArg = void;
export type TasksControllerGetUsageApiResponse = /** status 200  */ UsageRo;
export type TasksControllerGetUsageApiArg = void;
export type TasksControllerChangeVerifyApiResponse = unknown;
export type TasksControllerChangeVerifyApiArg = {
  verifyDto: VerifyDto;
};
export type TasksControllerGenerateOutputApiResponse = unknown;
export type TasksControllerGenerateOutputApiArg = {
  idDto: IdDto;
};
export type TasksControllerGetTasksStateApiResponse =
  /** status 200  */ TasksStateRo;
export type TasksControllerGetTasksStateApiArg = {
  ids: string[];
};
export type TasksControllerGetTaskOutputStateApiResponse =
  /** status 200  */ TaskOutputStateRo;
export type TasksControllerGetTaskOutputStateApiArg = {
  id: string;
};
export type TasksControllerSearchApiResponse = /** status 200  */ ItemsRo[];
export type TasksControllerSearchApiArg = {
  taskId: string;
  text: string;
};
export type TasksControllerGetPagesItemsApiResponse =
  /** status 200  */ ItemsRo[];
export type TasksControllerGetPagesItemsApiArg = {
  taskId: string;
  start: number;
  end: number;
};
export type TasksControllerGetTaskCurrentPageApiResponse =
  /** status 200  */ CurrentPageRo;
export type TasksControllerGetTaskCurrentPageApiArg = {
  taskId: string;
};
export type TasksControllerSetTaskCurrentPageApiResponse = unknown;
export type TasksControllerSetTaskCurrentPageApiArg = {
  setTaskCurrentPageDto: SetTaskCurrentPageDto;
};
export type TasksInternalControllerGetPdf2ImgTaskApiResponse =
  /** status 200  */ Pdf2ImgTaskRo;
export type TasksInternalControllerGetPdf2ImgTaskApiArg = void;
export type TasksInternalControllerSetPdf2ImgTaskResultApiResponse = unknown;
export type TasksInternalControllerSetPdf2ImgTaskResultApiArg = {
  pdf2ImgTaskResultDto: Pdf2ImgTaskResultDto;
};
export type TasksInternalControllerGetGeneratingTasksApiResponse =
  /** status 200  */ PdfGeneratingTaskRo;
export type TasksInternalControllerGetGeneratingTasksApiArg = void;
export type TasksInternalControllerSetGeneratingTasksApiResponse = unknown;
export type TasksInternalControllerSetGeneratingTasksApiArg = {
  generatingResultDto: GeneratingResultDto;
};
export type CompanyRo = {
  id: string;
  name: string;
};
export type UserRo = {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  roleNames: string[];
  isActive: boolean;
  documentCount: number;
  company?: CompanyRo;
  isManager?: boolean;
  lastLogin: string;
};
export type UserCreateDto = {
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  email: string;
  company?: string;
  isManager?: boolean;
};
export type AdminUserUpdateDto = {
  username: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  documentCount?: number;
  company?: string;
  isManager?: boolean;
};
export type UsersRo = {
  users: UserRo[];
};
export type UsernameDto = {
  username: string;
};
export type CompanyUserCreateDto = {
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  email: string;
  isManager?: boolean;
};
export type UserUpdateDto = {
  username: string;
  firstName?: string;
  lastName?: string;
  email?: string;
};
export type UserRoleDto = {
  username: string;
  roleIds: string[];
};
export type CheckPermissionDto = {
  username: string;
  permissions: (
    | 'ADMIN'
    | 'PDF2IMG'
    | 'GET_AI_TASKS'
    | 'GET_GENERATING_TASKS'
  )[];
};
export type MailResetPasswordDto = {
  email: string;
  callbackUrl: string;
};
export type ChangePasswordDto = {
  password: string;
};
export type CompaniesRo = {
  companies: CompanyRo[];
};
export type LoginRo = {
  token: string;
  user: UserRo;
};
export type LoginDto = {
  username: string;
  password: string;
};
export type PermissionViewRo = {
  id: string;
  category: string;
  group: string;
  name: string;
};
export type RoleRo = {
  id: string;
  name: string;
  permissions: 'ADMIN' | 'PDF2IMG' | 'GET_AI_TASKS' | 'GET_GENERATING_TASKS';
};
export type RoleDto = {
  name: string;
  permissions: (
    | 'ADMIN'
    | 'PDF2IMG'
    | 'GET_AI_TASKS'
    | 'GET_GENERATING_TASKS'
  )[];
};
export type UpdateRoleDto = {
  id: string;
  name: string;
  permissions: (
    | 'ADMIN'
    | 'PDF2IMG'
    | 'GET_AI_TASKS'
    | 'GET_GENERATING_TASKS'
  )[];
};
export type RolesRo = {
  roles: RoleRo[];
};
export type RoleIdDto = {
  id?: string;
};
export type IdRo = {
  id: string;
};
export type PagesRangeDto = {
  from: number;
  to: number;
};
export type SettingDto = {
  language: 'EN' | 'NL' | 'FR' | 'IT' | 'DE' | 'ES' | 'PT' | 'ZHS' | 'ZHT';
  autoRedact: boolean;
};
export type UrlRo = {
  url: string;
};
export type PageRo = {
  imagePath: string;
  aspectRatio: number;
};
export type SettingRo = {
  language: 'EN' | 'NL' | 'FR' | 'IT' | 'DE' | 'ES' | 'PT' | 'ZHS' | 'ZHT';
  autoRedact: boolean;
};
export type WorkspaceRo = {
  id: string;
  name: string;
  creator: string;
  description: string;
  state:
    | 'CREATE_IMAGES'
    | 'CREATING_IMAGES'
    | 'PENDING'
    | 'PREPARING'
    | 'PARTIAL_READY'
    | 'READY'
    | 'ERROR';
  defaultSetting: SettingRo;
  createdAt: string;
};
export type TaskRo = {
  id: string;
  creator: string;
  name: string;
  numberOfPages: number;
  state:
    | 'CREATE_IMAGES'
    | 'CREATING_IMAGES'
    | 'PENDING'
    | 'PREPARING'
    | 'PARTIAL_READY'
    | 'READY'
    | 'ERROR';
  outputState:
    | 'NOT_GENERATED'
    | 'GENERATE_OUTPUT'
    | 'GENERATING_OUTPUT'
    | 'GENERATED_OUTPUT'
    | 'ERROR';
  createdAt: string;
  isDeleted?: boolean;
  description: string;
  workspace?: WorkspaceRo;
  setting: SettingRo;
};
export type TasksRo = {
  tasks: TaskRo[];
};
export type WorkspacesRo = {
  workspaces: WorkspaceRo[];
};
export type IdDto = {
  id: string;
};
export type IdsDto = {
  ids: string[];
};
export type WorkspaceDto = {
  name: string;
  description?: string;
  defaultSetting: SettingDto;
};
export type BoxRo = {
  id: string;
  text?: string;
  rect: any[];
};
export type ItemRo = {
  id: string;
  text?: string;
  boxes: BoxRo[];
  accuracy: number;
  redact: boolean;
  custom: boolean;
  tag?:
    | 'NAME'
    | 'PERSON'
    | 'LOCATION'
    | 'ORGANIZATION'
    | 'PHONE'
    | 'EMAIL'
    | 'LINK'
    | 'DATE';
};
export type AddItemDto = {
  taskId: string;
  pageNumber: number;
  boxesId: string[];
};
export type AddCustomBoxDto = {
  taskId: string;
  pageNumber: number;
  rect: any[];
};
export type ItemsRo = {
  items: ItemRo[];
};
export type UpdateBoxDto = {
  id: string;
  rect: any[];
};
export type ChangeRedactStateDto = {
  itemId: string;
  isRedact: boolean;
};
export type UsageRo = {
  workspaceCount: number;
  taskCount: number;
  pageCount: number;
};
export type VerifyDto = {
  id: string;
  isVerify: boolean;
};
export type TasksStateRo = {
  tasksState: (
    | 'CREATE_IMAGES'
    | 'CREATING_IMAGES'
    | 'PENDING'
    | 'PREPARING'
    | 'PARTIAL_READY'
    | 'READY'
    | 'ERROR'
  )[];
};
export type TaskOutputStateRo = {
  outputState:
    | 'NOT_GENERATED'
    | 'GENERATE_OUTPUT'
    | 'GENERATING_OUTPUT'
    | 'GENERATED_OUTPUT'
    | 'ERROR';
};
export type CurrentPageRo = {
  index: number;
};
export type SetTaskCurrentPageDto = {
  id: string;
  index: number;
};
export type Pdf2ImgTaskRo = {
  id: string;
  url: string;
  folderName: string;
  pagesRanges: string[];
};
export type Pdf2ImgPageDto = {
  imagePath: string;
  aspectRatio: number;
};
export type Pdf2ImgTaskResultDto = {
  id: string;
  pages: Pdf2ImgPageDto[];
};
export type GeneratingBoxRo = {
  rect: any[];
};
export type GeneratingItemRo = {
  boxes: GeneratingBoxRo[];
  redact: boolean;
};
export type GeneratingPageRo = {
  items: GeneratingItemRo[];
  imagePath: string;
};
export type PdfGeneratingTaskRo = {
  id: string;
  name: string;
  pages: GeneratingPageRo[];
};
export type GeneratingResultDto = {
  id: string;
  outFilePath: string;
};
export const {
  useUsersControllerCreateMutation,
  useUsersControllerUpdateMutation,
  useUsersControllerFindQuery,
  useUsersControllerDeleteMutation,
  useUsersControllerCreateCompanyUserMutation,
  useUsersControllerFindCompanyUsersQuery,
  useUsersControllerUserProfileQuery,
  useUsersControllerUpdateUserProfileMutation,
  useUsersControllerSuspendMutation,
  useUsersControllerActivateMutation,
  useUsersControllerAssignRoleMutation,
  useUsersControllerCheckPermissionMutation,
  useUsersControllerMailResetPasswordMutation,
  useUsersControllerCheckResetPasswordCredQuery,
  useUsersControllerResetPasswordMutation,
  useUsersControllerChangePasswordMutation,
  useUsersControllerGetCompaniesQuery,
  useAuthControllerLoginMutation,
  useAuthControllerSignupMutation,
  useRolesControllerReadPermissionsQuery,
  useRolesControllerCreateRoleMutation,
  useRolesControllerUpdateRoleMutation,
  useRolesControllerFindRoleQuery,
  useRolesControllerDeleteRoleMutation,
  useRolesControllerReadRoleQuery,
  useMediaControllerUploadDocumentMutation,
  useMediaControllerGetTaskFileUrlQuery,
  useMediaControllerGetTaskPagesQuery,
  useMediaControllerGetTaskOutFileUrlQuery,
  useTasksControllerGetTaskQuery,
  useTasksControllerGetWorkspaceTasksQuery,
  useTasksControllerGetWorkspacesQuery,
  useTasksControllerRemoveMutation,
  useTasksControllerDeleteMutation,
  useTasksControllerDeleteCompanyTasksMutation,
  useTasksControllerRemoveManyMutation,
  useTasksControllerCreateWorkspaceMutation,
  useTasksControllerDeleteWorkspaceMutation,
  useTasksControllerGetCompanyTasksQuery,
  useTasksControllerGetCompanyWorkspacesQuery,
  useTasksControllerAddItemMutation,
  useTasksControllerDeleteItemMutation,
  useTasksControllerAddCustomBoxMutation,
  useTasksControllerGetItemsQuery,
  useTasksControllerUpdateBoxMutation,
  useTasksControllerChangeRedactStateMutation,
  useTasksControllerGetLastModifiedTaskQuery,
  useTasksControllerGetUsageQuery,
  useTasksControllerChangeVerifyMutation,
  useTasksControllerGenerateOutputMutation,
  useTasksControllerGetTasksStateQuery,
  useTasksControllerGetTaskOutputStateQuery,
  useTasksControllerSearchQuery,
  useTasksControllerGetPagesItemsQuery,
  useTasksControllerGetTaskCurrentPageQuery,
  useTasksControllerSetTaskCurrentPageMutation,
  useTasksInternalControllerGetPdf2ImgTaskMutation,
  useTasksInternalControllerSetPdf2ImgTaskResultMutation,
  useTasksInternalControllerGetGeneratingTasksMutation,
  useTasksInternalControllerSetGeneratingTasksMutation,
} = api;
