import { useState, Dispatch, FC, SetStateAction, useEffect } from 'react';
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  SwipeableDrawer,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import HomeSvg from 'src/components/atoms/svg/HomeSvg';
import HistorySvg from 'src/components/atoms/svg/HistorySvg';
import ProfileSvg from 'src/components/atoms/svg/ProfileSvg';
import NewTaskSvg from 'src/components/atoms/svg/NewTaskSvg';
import ResumeSvg from 'src/components/atoms/svg/ResumeSvg';
import NewFileSvg from 'src/components/atoms/svg/NewFileSvg';
import UsersSvg from 'src/components/atoms/svg/UsersSvg';
import { useAppSelector, useAppDispatch } from 'src/app/reduxCustomHooks';
import { useTasksControllerGetLastModifiedTaskQuery } from 'src/app/services/api.generated';
import AllTasksSvg from 'src/components/atoms/svg/AllTasksSvg';
import SignOutSvg from 'src/components/atoms/svg/SignOutSvg';
import { logoutAction } from 'src/app/slices/authSlice';
import { api } from 'src/app/services/api';
import { toast } from 'react-toastify';
import ListItems from './ListItems';
import { sidebarMinWidth } from 'src/components/templates/DashboardTemplate';
import { shallowEqual } from 'react-redux';

type SidebarPropsType = {
  openSandwichMenu: boolean;
  setOpenSandwichMenu: Dispatch<SetStateAction<boolean>>;
};

const Sidebar: FC<SidebarPropsType> = ({
  openSandwichMenu,
  setOpenSandwichMenu,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isHovering) {
      setIsOpen(false);
    }
    const timer = setTimeout(() => {
      setIsOpen(isHovering);
    }, 300);
    return () => clearInterval(timer);
  }, [isHovering]);

  const { pathname } = useLocation(),
    navigate = useNavigate(),
    { t } = useTranslation(),
    dispatch = useAppDispatch(),
    theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  const { isAdmin, isManager } = useAppSelector(
    (state) => ({
      isAdmin: state.auth.isAdmin,
      isManager: state.auth.isManager,
    }),
    shallowEqual
  );

  const { data } = useTasksControllerGetLastModifiedTaskQuery();

  const logoutHandler = () => {
    dispatch(logoutAction());
    dispatch(api.util.resetApiState());
    toast.success(t('youAreSuccessfullyLoggedOut'));
    navigate('/login');
  };

  const listItemsArray = [
    {
      title: t('home'),
      path: '/',
      icon: (
        <HomeSvg
          selected={
            pathname === '/' ||
            pathname === '/createTasks' ||
            pathname === '/createWorkspace' ||
            pathname.includes('edit')
          }
          isSubItem={false}
        />
      ),
      subPages: [
        {
          path: '/createTasks',
          title: t('newTask'),
          icon: (
            <NewFileSvg
              sx={{ fontSize: '30px' }}
              selected={pathname === '/createTasks'}
            />
          ),
        },
        {
          path: '/createWorkspace',
          title: t('newWorkspace'),
          icon: (
            <NewTaskSvg
              sx={{ fontSize: '30px' }}
              selected={pathname === '/createWorkspace'}
            />
          ),
        },
        {
          path: '/edit/' + data?.id,
          title: t('resumeJob'),
          icon: (
            <ResumeSvg
              sx={{ fontSize: '30px' }}
              selected={pathname.includes('/edit')}
            />
          ),
        },
      ],
    },
    {
      title: t('history'),
      path: '/history',
      icon: <HistorySvg selected={pathname === '/history'} isSubItem={false} />,
    },
    {
      title: t('profile'),
      path: '/profile',
      icon: (
        <ProfileSvg
          selected={
            pathname === '/profile' ||
            pathname === '/profile/users' ||
            pathname === '/profile/allTasks'
          }
          isSubItem={false}
        />
      ),
      subPages:
        isAdmin || isManager
          ? [
              {
                path: '/profile/users',
                title: t('users'),
                icon: (
                  <UsersSvg
                    sx={{ fontSize: '30px' }}
                    selected={pathname === '/profile/users'}
                  />
                ),
              },
              {
                path: '/profile/allTasks',
                title: t('allTasks'),
                icon: (
                  <AllTasksSvg
                    sx={{ fontSize: '30px' }}
                    selected={pathname === '/profile/allTasks'}
                  />
                ),
              },
            ]
          : undefined,
    },
  ];

  return (
    <SwipeableDrawer
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      variant={isMd ? 'permanent' : 'temporary'}
      open={openSandwichMenu}
      onOpen={() => setOpenSandwichMenu(true)}
      PaperProps={{
        sx: {
          transition: '300ms',
          backgroundColor: 'customGrey.light',
          width: isOpen || !isMd ? 270 : sidebarMinWidth,
          whiteSpace: 'nowrap',
          boxSizing: 'border-box',
          px: 0,
          overflowX: 'hidden',
        },
      }}
      sx={{
        width: sidebarMinWidth,
      }}
      anchor="left"
      onClose={() => setOpenSandwichMenu(false)}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: `${theme.spacing(2)} auto ${theme.spacing(6)}`,
        }}
      >
        <img
          className="logo"
          src={process.env.PUBLIC_URL + '/icons/svg/logo.svg'}
          alt="scann pbl site logo"
          style={{
            transition: '300ms',
            width: 50,
            height: 30,
            transformOrigin: 'center top',
            transform: isOpen ? 'scale(1.7)' : undefined,
          }}
        />
      </div>
      <List
        component={Stack}
        alignItems="center"
        justifyContent="space-between"
        sx={{ height: '100%', transition: '300ms' }}
      >
        <Box sx={{ width: '100%' }}>
          <ListItems
            list={listItemsArray}
            isOpen={isOpen}
            setOpenSandwichMenu={setOpenSandwichMenu}
          />
        </Box>
        <Box sx={{ width: '100%' }}>
          <ListItemButton
            sx={{
              transform: '300ms',
              my: 1,
              px: 3.7,
            }}
            onClick={logoutHandler}
          >
            <ListItemIcon
              sx={{
                minWidth: isOpen || !isMd ? 35 : undefined,
                transition: '300ms',
              }}
            >
              <SignOutSvg />
            </ListItemIcon>
            <ListItemText
              primary={t('signOut')}
              primaryTypographyProps={{
                color: 'error.light',
                fontSize: '18px',
              }}
            />
          </ListItemButton>
        </Box>
      </List>
    </SwipeableDrawer>
  );
};

export default Sidebar;
