import { FC, useState } from 'react';
import {
  Button,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import i18n from 'src/i18n';

export const LanguageSwitcher: FC = () => {
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >();

  const closeMenuHandler = () => setAnchorEl(null);
  const openMenuHandler = ({
    currentTarget,
  }: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
    setAnchorEl(currentTarget);

  const open = Boolean(anchorEl);
  const id = open ? 'choose language' : undefined;

  const langsArray = [
    {
      txt: 'English',
      val: 'en',
    },
    {
      txt: 'Dutch',
      val: 'nl',
    },
  ];

  const languageChangeHandler = (lng: string) => {
    i18n.changeLanguage(lng);
    closeMenuHandler();
  };

  return (
    <>
      <Button sx={{ width: '51px', height: '51px' }} onClick={openMenuHandler}>
        <Typography
          textTransform="uppercase"
          fontSize={24}
          fontWeight={500}
          color={grey[500]}
        >
          {i18n.language}
        </Typography>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closeMenuHandler}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            backgroundColor: 'customGrey.light',
            borderTopRightRadius: 0,
            py: 2,
          },
        }}
      >
        {langsArray.map(({ txt, val }, index) => {
          let isSelected = i18n.language === val;
          return (
            <MenuItem
              key={index}
              selected={isSelected}
              onClick={() => languageChangeHandler(val)}
            >
              <ListItemText>
                <Typography
                  align="center"
                  fontSize={14}
                  color={isSelected ? 'primary' : undefined}
                >
                  {txt}
                </Typography>
              </ListItemText>
            </MenuItem>
          );
        })}
      </Popover>
    </>
  );
};

export default LanguageSwitcher;
