import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

type HistorySvgPropsType = {
  selected?: boolean;
  isSubItem?: boolean;
} & SvgIconProps;

const HistorySvg: FC<HistorySvgPropsType> = ({
  selected = false,
  isSubItem = true,
  ...svgProps
}) => {
  return (
    <SvgIcon
      {...svgProps}
      sx={{
        filter: ({ palette }) =>
          selected && !isSubItem
            ? `drop-shadow(0 0 0.75rem ${palette.primary.main})`
            : undefined,
        '& path': {
          fill: ({ palette }) =>
            selected ? palette.primary.main : palette.customGrey.ultraLight,
        },
        ...svgProps.sx,
      }}
    >
      <g opacity="0.75">
        <path
          d="M8 1H4C2.34315 1 1 2.34315 1 4V8C1 9.65685 2.34315 11 4 11H8C9.65685 11 11 9.65685 11 8V4C11 2.34315 9.65685 1 8 1Z"
          fill="white"
        />
        <path
          d="M20 1H16C14.3431 1 13 2.34315 13 4V8C13 9.65685 14.3431 11 16 11H20C21.6569 11 23 9.65685 23 8V4C23 2.34315 21.6569 1 20 1Z"
          fill="white"
        />
        <path
          d="M8 13H4C2.34315 13 1 14.3431 1 16V20C1 21.6569 2.34315 23 4 23H8C9.65685 23 11 21.6569 11 20V16C11 14.3431 9.65685 13 8 13Z"
          fill="white"
        />
        <path
          d="M20 13H16C14.3431 13 13 14.3431 13 16V20C13 21.6569 14.3431 23 16 23H20C21.6569 23 23 21.6569 23 20V16C23 14.3431 21.6569 13 20 13Z"
          fill="white"
        />
      </g>
    </SvgIcon>
  );
};

export default HistorySvg;
