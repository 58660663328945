import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

type ResumeSvgPropsType = {
  selected?: boolean;
} & SvgIconProps;

const ResumeSvg: FC<ResumeSvgPropsType> = ({
  selected = false,
  ...svgProps
}) => {
  return (
    <SvgIcon
      {...svgProps}
      sx={{
        '& path': {
          fill: ({ palette }) =>
            selected ? palette.primary.main : palette.customGrey.ultraLight,
        },
        ...svgProps.sx,
      }}
    >
      <path d="M11.348 6.07006C11.1215 6.07006 10.9378 6.25371 10.9378 6.48023V12.496C10.9378 12.873 10.6312 13.1797 10.2542 13.1797H1.50395C1.12701 13.1797 0.820338 12.873 0.820338 12.496V3.74577C0.820338 3.36883 1.12701 3.06216 1.50395 3.06216H7.51977C7.74629 3.06216 7.92994 2.87851 7.92994 2.65199C7.92994 2.42547 7.74629 2.24182 7.51977 2.24182H1.50395C0.674674 2.24182 0 2.91649 0 3.74577V12.496C0 13.3253 0.674674 14 1.50395 14H10.2542C11.0835 14 11.7582 13.3253 11.7582 12.496V6.48023C11.7582 6.25371 11.5745 6.07006 11.348 6.07006Z" />
      <path d="M13.7201 1.0533L12.9466 0.279858C12.5735 -0.093286 11.9664 -0.093286 11.5932 0.279858L5.40582 6.46722C5.34856 6.52448 5.30954 6.59741 5.29363 6.67682L4.90689 8.61038C4.88001 8.74486 4.92209 8.88388 5.01909 8.98084C5.09677 9.05853 5.20142 9.101 5.3091 9.101C5.33587 9.101 5.36278 9.09837 5.38952 9.09304L7.32309 8.7063C7.4025 8.69042 7.47542 8.65137 7.53268 8.59411L13.7201 2.40677C13.7201 2.40677 13.7201 2.40677 13.7201 2.40675C14.0932 2.03363 14.0932 1.42647 13.7201 1.0533ZM7.04045 7.92625L5.83199 8.16797L6.07371 6.95951L11.1098 1.92338L12.0766 2.89017L7.04045 7.92625ZM13.14 1.82671L12.6566 2.31011L11.6898 1.34332L12.1732 0.859946C12.2265 0.806624 12.3133 0.806596 12.3666 0.859918L13.14 1.63336C13.1933 1.68665 13.1933 1.77342 13.14 1.82671Z" />
    </SvgIcon>
  );
};

export default ResumeSvg;
