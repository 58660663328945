import { FC, useCallback } from 'react';
import { List, ListItemIcon, ListItemText } from '@mui/material';
import { useLocation } from 'react-router';
import { listSubItemType } from 'src/types/listItemsTypes';
import { useAppDispatch } from 'src/app/reduxCustomHooks';
import { setSelectedEmployeeAction } from 'src/app/slices/employeeSlice';
import CustomListItemButton from 'src/components/atoms/CustomListItemButton';

type ListSubItemsPropsType = {
  subItems: listSubItemType[];
};

const ListSubItems: FC<ListSubItemsPropsType> = ({ subItems }) => {
  const dispatch = useAppDispatch(),
    { pathname } = useLocation();

  const resetSelectedEmployeeProfile = useCallback(
    (path: string) => {
      if (path.includes('profile')) {
        dispatch(setSelectedEmployeeAction(null));
      }
    },
    [dispatch]
  );

  return (
    <List
      disablePadding
      sx={{
        overflow: 'hidden',
        backgroundColor: 'customGrey.subListBackground',
        borderRadius: '15px',
        p: 1,
      }}
    >
      {subItems?.map(({ path, title, icon }, index) => {
        const isSelected = pathname === path;
        return (
          <CustomListItemButton
            key={index}
            to={path}
            onClick={() => resetSelectedEmployeeProfile(path)}
            sx={{
              px: 1.2,
              my: 0.3,
              minHeight: '40.5px',
              backgroundColor: isSelected
                ? 'customGrey.sidebarSubListSelectedBackground'
                : undefined,
              borderRadius: '15px',
              boxShadow: isSelected
                ? '0px 4px 4px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(50, 50, 71, 0.006)'
                : undefined,
            }}
          >
            <ListItemIcon
              sx={{
                height: '18px',
                width: '20px',
                mr: 1,
                minWidth: 'auto',
              }}
            >
              {icon}
            </ListItemIcon>
            <ListItemText
              sx={{
                transition: '300ms',
                '& span': {
                  fontSize: '14px',
                },
                width: 'auto',
              }}
              primary={title}
              primaryTypographyProps={{
                color: 'white',
              }}
            />
          </CustomListItemButton>
        );
      })}
    </List>
  );
};

export default ListSubItems;
