import { createSlice } from '@reduxjs/toolkit';
import { WorkspaceRo } from '../services/api.generated';

type workspaceSliceType = {
  selectedWorkspace: WorkspaceRo | null;
};

const initialState: workspaceSliceType = {
  selectedWorkspace: null,
};

const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    setSelectedWorkspace: (
      state,
      { payload }: { payload: workspaceSliceType['selectedWorkspace'] }
    ) => {
      state.selectedWorkspace = payload;
    },
  },
});

export const { setSelectedWorkspace: setSelectedWorkspaceAction } =
  workspaceSlice.actions;

export default workspaceSlice.reducer;
