import { createSlice } from '@reduxjs/toolkit';
import type { ItemRo, TaskRo } from '../services/api.generated';
import { api } from '../services/api';
import { logoutAction } from './authSlice';

type editPageSliceType = {
  task: TaskRo | null;
  pages: ItemRo[][];
  openRightSidebar: boolean;
};

const localSettings = localStorage.getItem('appSettings');

const initialState: editPageSliceType = {
  task: null,
  pages: [],
  openRightSidebar: localSettings
    ? JSON.parse(localSettings).openRightSidebar
    : true,
};

const editPageSlice = createSlice({
  name: 'editPage',
  initialState,
  reducers: {
    changeRightSidebarState: (state, { payload }: { payload: boolean }) => {
      localStorage.setItem(
        'appSettings',
        JSON.stringify({
          openRightSidebar: payload,
        })
      );
      state.openRightSidebar = payload;
    },
  },
  extraReducers: ({ addMatcher, addCase }) => {
    // ========================= LOGOUT ========================= //
    addCase(logoutAction, () => {
      localStorage.removeItem('appSettings');
      return initialState;
    });
    addMatcher(
      api.endpoints.tasksControllerGetTask.matchFulfilled,
      (state, { payload }) => {
        state.task = payload;
      }
    );
    addMatcher(
      api.endpoints.tasksControllerGetPagesItems.matchFulfilled,
      (state, { payload, meta }) => {
        for (let i = 0; i < payload.length; i++) {
          state.pages[i + meta.arg.originalArgs.start] = payload[i].items;
        }
      }
    );
    addMatcher(
      api.endpoints.tasksControllerChangeRedactState.matchFulfilled,
      (state, { meta }) => {
        const redactedItemId =
          meta.arg.originalArgs.changeRedactStateDto.itemId;
        const currentRedactStatus =
          meta.arg.originalArgs.changeRedactStateDto.isRedact;
        let newPages: ItemRo[][] = [...state.pages];
        newPages.find((page) => {
          const itemIndex = page.map((item) => item.id).indexOf(redactedItemId);
          if (itemIndex > -1) {
            const item = page[itemIndex];
            if (!currentRedactStatus && item.custom) {
              page.splice(itemIndex, 1);
            } else {
              item.redact = currentRedactStatus;
            }
            return true;
          }
          return false;
        });
        state.pages = newPages;
      }
    );
    addMatcher(
      api.endpoints.tasksControllerDeleteItem.matchFulfilled,
      (state, { meta }) => {
        const deletedItemId = meta.arg.originalArgs.idDto.id;
        let newPages: ItemRo[][] = [...state.pages];
        newPages.find((page) => {
          for (var i = 0; i < page.length; i++) {
            if (page[i].id === deletedItemId) {
              if (page[i].custom) {
                page.splice(i, 1);
                return true;
              } else {
                page[i].redact = false;
              }
            }
          }
          return false;
        });
        state.pages = newPages;
      }
    );
    addMatcher(
      api.endpoints.tasksControllerAddCustomBox.matchFulfilled,
      (state, { payload, meta }) => {
        const pageNumber = meta.arg.originalArgs.addCustomBoxDto.pageNumber;
        let newPages: ItemRo[][] = [...state.pages];
        newPages[pageNumber].push(payload);
        state.pages = newPages;
      }
    );
    addMatcher(
      api.endpoints.tasksControllerAddItem.matchFulfilled,
      (state, { payload, meta }) => {
        const pageNumber = meta.arg.originalArgs.addItemDto.pageNumber;
        let newPages: ItemRo[][] = [...state.pages];
        newPages[pageNumber].push(payload);
        state.pages = newPages;
      }
    );
    addMatcher(
      api.endpoints.tasksControllerGetTaskOutputState_custom.matchFulfilled,
      (state, { payload }) => {
        if (state.task) {
          state.task.outputState = payload.outputState;
        }
      }
    );
    addMatcher(
      api.endpoints.tasksControllerGenerateOutput.matchFulfilled,
      (state) => {
        if (state.task) {
          state.task.outputState = 'GENERATING_OUTPUT';
        }
      }
    );
  },
});

export const { changeRightSidebarState: changeRightSidebarStateAction } =
  editPageSlice.actions;

export default editPageSlice.reducer;
