import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

type UsersSvgPropsType = {
  selected?: boolean;
} & SvgIconProps;

const UsersSvg: FC<UsersSvgPropsType> = ({ selected = false, ...svgProps }) => {
  return (
    <SvgIcon
      {...svgProps}
      sx={{
        '& path': {
          fill: ({ palette }) =>
            selected ? palette.primary.main : palette.customGrey.ultraLight,
        },
        ...svgProps.sx,
      }}
    >
      <path d="M7.00001 4.09082C5.90068 4.09082 5.00635 4.98515 5.00635 6.08448C5.00635 7.18381 5.90071 8.07814 7.00001 8.07814C8.09931 8.07814 8.99367 7.18378 8.99367 6.08448C8.99367 4.98518 8.09934 4.09082 7.00001 4.09082ZM7.00001 7.19209C6.3892 7.19209 5.89242 6.69515 5.89242 6.08451C5.89242 5.4737 6.3892 4.97692 7.00001 4.97692C7.61081 4.97692 8.10759 5.4737 8.10759 6.08451C8.10759 6.69531 7.61081 7.19209 7.00001 7.19209Z" />
      <path d="M7.00009 7.17725C5.36335 7.17725 4.03174 8.50886 4.03174 10.1456V10.8102C4.03174 11.0549 4.23006 11.2532 4.47479 11.2532C4.71949 11.2532 4.91784 11.0549 4.91784 10.8102V10.1456C4.91784 8.99738 5.8519 8.06332 7.00012 8.06332C8.14834 8.06332 9.0824 8.99755 9.0824 10.1456V10.8102C9.0824 11.0549 9.28073 11.2532 9.52545 11.2532C9.77015 11.2532 9.9685 11.0549 9.9685 10.8102V10.1456C9.96845 8.50886 8.63684 7.17725 7.00009 7.17725Z" />
      <path d="M11.0317 2.74707C9.93242 2.74707 9.03809 3.64143 9.03809 4.74073C9.03809 5.84004 9.93244 6.7344 11.0317 6.7344C12.1312 6.7344 13.0254 5.84004 13.0254 4.74073C13.0254 3.64143 12.1311 2.74707 11.0317 2.74707ZM11.0317 5.84832C10.4209 5.84832 9.92416 5.35154 9.92416 4.74073C9.92416 4.12993 10.4209 3.63315 11.0317 3.63315C11.6426 3.63315 12.1393 4.12993 12.1393 4.74073C12.1393 5.35154 11.6426 5.84832 11.0317 5.84832Z" />
      <path d="M11.0317 5.8335C9.67408 5.8335 8.48942 6.7537 8.15123 8.07142C8.09039 8.3083 8.23318 8.54974 8.47006 8.61061C8.70707 8.67205 8.94838 8.5288 9.00925 8.29178C9.247 7.36598 10.0787 6.7196 11.0317 6.7196C12.1799 6.7196 13.114 7.65382 13.114 8.80188V9.46644C13.114 9.71114 13.3123 9.90949 13.5571 9.90949C13.8018 9.90949 14.0001 9.71117 14.0001 9.46644V8.80185C14.0001 7.16514 12.6684 5.8335 11.0317 5.8335Z" />
      <path d="M2.96827 2.74707C1.86894 2.74707 0.974609 3.64143 0.974609 4.74073C0.974609 5.84004 1.86897 6.7344 2.96827 6.7344C4.0676 6.7344 4.96193 5.84004 4.96193 4.74073C4.96193 3.64143 4.0676 2.74707 2.96827 2.74707ZM2.96827 5.84832C2.35747 5.84832 1.86068 5.35154 1.86068 4.74073C1.86068 4.12993 2.35747 3.63315 2.96827 3.63315C3.57908 3.63315 4.07586 4.12993 4.07586 4.74073C4.07586 5.35154 3.57908 5.84832 2.96827 5.84832Z" />
      <path d="M5.83423 8.05665C5.49812 6.74762 4.31963 5.8335 2.96836 5.8335C1.33161 5.83347 0 7.16511 0 8.80182V9.46639C0 9.71109 0.198324 9.90944 0.443051 9.90944C0.68775 9.90944 0.886101 9.71111 0.886101 9.46639V8.80182C0.886101 7.65377 1.82016 6.71954 2.96838 6.71954C3.915 6.71954 4.74053 7.35988 4.97593 8.27696C5.03677 8.51384 5.27734 8.65619 5.51526 8.59595C5.75225 8.53511 5.89507 8.29353 5.83423 8.05665Z" />
    </SvgIcon>
  );
};

export default UsersSvg;
