import { AxiosRequestConfig } from 'axios';
import {
  api as generatedApi,
  MediaControllerGetTaskOutFileUrlApiArg,
  MediaControllerGetTaskOutFileUrlApiResponse,
  TasksControllerGetTaskApiArg,
  TasksControllerGetTaskApiResponse,
  TasksControllerGetTasksStateApiArg,
  TasksControllerGetTasksStateApiResponse,
  TasksControllerGetWorkspaceTasksApiArg,
  TasksControllerGetWorkspaceTasksApiResponse,
  TasksControllerGetTaskOutputStateApiResponse,
  TasksControllerGetTaskOutputStateApiArg,
  MediaControllerUploadDocumentApiArg,
  MediaControllerUploadDocumentApiResponse,
} from './api.generated';

export const api = generatedApi
  .enhanceEndpoints({
    addTagTypes: ['User', 'Workspace', 'Companies', 'Task'],
    endpoints: {
      // ======================= WORKSPACE ======================= //
      tasksControllerGetWorkspaces: {
        providesTags: (result) =>
          result
            ? [
                ...result.workspaces.map((item) => ({
                  type: 'Workspace' as const,
                  id: item.id,
                })),
                'Workspace',
              ]
            : ['Workspace'],
      },
      tasksControllerGetCompanyWorkspaces: {
        providesTags: (result) =>
          result
            ? [
                ...result.workspaces.map((item) => ({
                  type: 'Workspace' as const,
                  id: item.id,
                })),
                'Workspace',
              ]
            : ['Workspace'],
      },
      tasksControllerCreateWorkspace: {
        invalidatesTags: () => ['Workspace'],
      },
      // ======================= COMPANIES ======================= //
      usersControllerGetCompanies: {
        providesTags: (result) =>
          result
            ? [
                ...result.companies.map(({ id }) => ({
                  type: 'Companies' as const,
                  id,
                })),
                'Companies',
              ]
            : ['Companies'],
      },
      // ======================= USERS ======================= //
      usersControllerFind: {
        providesTags: (result) =>
          result
            ? [
                ...result.users.map(({ username }) => ({
                  type: 'User' as const,
                  id: username,
                })),
                'User',
              ]
            : ['User'],
      },
      usersControllerFindCompanyUsers: {
        providesTags: (result) =>
          result
            ? [
                ...result.users.map(({ username }) => ({
                  type: 'User' as const,
                  id: username,
                })),
                'User',
              ]
            : ['User'],
      },
      usersControllerUpdate: {
        invalidatesTags: (result, error, { adminUserUpdateDto }) => [
          { type: 'User', id: adminUserUpdateDto.username },
          'Companies',
        ],
      },
      usersControllerCreate: {
        invalidatesTags: () => ['User', 'Companies'],
      },
      usersControllerCreateCompanyUser: {
        invalidatesTags: () => ['User', 'Companies'],
      },
      usersControllerSuspend: {
        invalidatesTags: (result, error, { usernameDto }) => [
          { type: 'User', id: usernameDto.username },
        ],
      },
      usersControllerActivate: {
        invalidatesTags: (result, error, { usernameDto }) => [
          { type: 'User', id: usernameDto.username },
        ],
      },
      usersControllerAssignRole: {
        invalidatesTags: () => ['User'],
      },
      // ========================= TASK ========================= //
      tasksControllerGetWorkspaceTasks: {
        providesTags: (result) =>
          result
            ? [
                ...result.tasks.map(({ id }) => ({
                  type: 'Task' as const,
                  id,
                })),
                'Task',
              ]
            : ['Task'],
      },
      tasksControllerGetCompanyTasks: {
        providesTags: (result) =>
          result
            ? [
                ...result.tasks.map(({ id }) => ({
                  type: 'Task' as const,
                  id,
                })),
                'Task',
              ]
            : ['Task'],
      },
      tasksControllerDelete: {
        invalidatesTags: (result, error, { idDto: { id } }) => [
          { type: 'Task', id },
        ],
      },
      tasksControllerRemove: {
        invalidatesTags: (result, error, { idDto: { id } }) => [
          { type: 'Task', id },
        ],
      },
      tasksControllerRemoveMany: {
        invalidatesTags: (result, error, { idsDto: { ids } }) =>
          ids.map((id) => ({ type: 'Task', id })),
      },
      // ======================= LOGOUT ======================= //
      authControllerLogin: {
        invalidatesTags: () => ['User', 'Workspace', 'Companies'],
      },
    },
  })
  .injectEndpoints({
    endpoints: (build) => ({
      tasksControllerGetWorkspaceTasks_custom: build.mutation<
        TasksControllerGetWorkspaceTasksApiResponse,
        TasksControllerGetWorkspaceTasksApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/tasks/tasks`,
          params: {
            pageNumber: queryArg.pageNumber,
            workspaceId: queryArg.workspaceId,
          },
        }),
      }),
      mediaControllerGetTaskOutFileUrl_custom: build.mutation<
        MediaControllerGetTaskOutFileUrlApiResponse,
        MediaControllerGetTaskOutFileUrlApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/media/taskOutFile/${queryArg.taskId}/url`,
        }),
      }),
      tasksControllerGetTasksState_custom: build.mutation<
        TasksControllerGetTasksStateApiResponse,
        TasksControllerGetTasksStateApiArg
      >({
        query: (queryArg) => {
          let result = '/api/v1/tasks/tasks-state';
          queryArg.ids.forEach((id, index) => {
            if (index === 0) {
              result = result + `?ids=${id}`;
            } else {
              result = result + `&ids=${id}`;
            }
            return result;
          });

          return {
            url: result,
          };
        },
      }),
      tasksControllerGetTaskOutputState_custom: build.mutation<
        TasksControllerGetTaskOutputStateApiResponse,
        TasksControllerGetTaskOutputStateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/tasks/task-output-state`,
          params: { id: queryArg.id },
        }),
      }),
      tasksControllerGetTask_custom: build.mutation<
        TasksControllerGetTaskApiResponse,
        TasksControllerGetTaskApiArg
      >({
        query: (queryArg) => ({ url: `/api/v1/tasks/task/${queryArg.id}` }),
      }),
      customMediaControllerUploadDocument: build.mutation<
        MediaControllerUploadDocumentApiResponse,
        MediaControllerUploadDocumentApiArg & {
          abortController: AbortController;
          onUploadProgress: AxiosRequestConfig['onUploadProgress'];
        }
      >({
        query: ({ body, abortController, onUploadProgress }) => {
          const formData = new FormData();
          formData.append('pagesRanges', JSON.stringify(body.pagesRanges));

          if (body.setting) {
            formData.append('setting', JSON.stringify(body.setting));
          }
          Object.keys(body).forEach((k) => {
            const key = k as keyof MediaControllerUploadDocumentApiArg['body'];
            if (key === 'setting' || key === 'pagesRanges') {
              return;
            }
            const value = body[key];
            if (!value) {
              return;
            }
            formData.append(key, value);
          });

          return {
            url: `/api/v1/media`,
            method: 'POST',
            body: formData,
            abortController,
            onUploadProgress,
          };
        },
      }),
    }),
  });

// TODO: convert all customMutations to lazyQuery
export const {
  useTasksControllerGetWorkspaceTasks_customMutation,
  useMediaControllerGetTaskOutFileUrl_customMutation,
  useTasksControllerGetTasksState_customMutation,
  useTasksControllerGetTask_customMutation,
  useTasksControllerGetTaskOutputState_customMutation,
  useLazyTasksControllerSearchQuery,
  useCustomMediaControllerUploadDocumentMutation,
} = api;
