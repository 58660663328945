import type { FC } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  sidebarMinWidth,
  workspaceSidebarWidth,
} from 'src/components/templates/DashboardTemplate';

type WorkspaceSidebarPropsType = {};

export const WorkspaceSidebar: FC<WorkspaceSidebarPropsType> = () => {
  const { t } = useTranslation();

  return (
    <Drawer
      variant="permanent"
      PaperProps={{
        sx: {
          left: sidebarMinWidth + 'px',
          width: workspaceSidebarWidth + 'px',
        },
      }}
      sx={{
        zIndex: 0,
        width: workspaceSidebarWidth + 'px',
        left: sidebarMinWidth + 'px',
      }}
    >
      <List>
        <ListItem>
          <ListItemButton onClick={() => {}}>
            <ListItemIcon></ListItemIcon>
            <ListItemText primary={t('showFile')} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={() => {}}>
            <ListItemIcon></ListItemIcon>
            <ListItemText primary={t('downloadFile')} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={() => {}}>
            <ListItemIcon></ListItemIcon>
            <ListItemText primary={t('deleteFile')} />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
};
