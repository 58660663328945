import { createSlice, AnyAction } from '@reduxjs/toolkit';
import { LoginRo, UserRo } from '../services/api.generated';
import { api } from '../services/api';

type authSliceType = {
  user: UserRo | null;
  token: LoginRo['token'];
  isAdmin: boolean;
  isManager: boolean;
};

const isAdmin = (user: UserRo) =>
  !!user.roleNames.find((item) => item === 'ADMIN') ||
  user.username === 'myomnipotentaccount';
const isManager = (user: UserRo) =>
  !!user.isManager || user.username === 'myomnipotentaccount';

const initialState = () => {
  const localStorageUserInfo = localStorage.getItem('user');
  const localStorageUserToken = localStorage.getItem('token');
  let result: authSliceType = {
    user: null,
    token: '',
    isAdmin: false,
    isManager: false,
  };
  if (localStorageUserInfo && localStorageUserToken) {
    const userLocalInfo: UserRo = JSON.parse(localStorageUserInfo);
    const userLocalToken: LoginRo['token'] = localStorageUserToken;
    result = {
      user: userLocalInfo,
      token: userLocalToken,
      isAdmin: isAdmin(userLocalInfo),
      isManager: isManager(userLocalInfo),
    };
  }
  return result;
};

const isAuthenticated = (action: AnyAction) => {
  return (
    api.endpoints.authControllerLogin.matchFulfilled(action) ||
    api.endpoints.authControllerSignup.matchFulfilled(action)
  );
};

const authSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    logout: () => {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      return initialState();
    },
  },
  extraReducers: ({ addMatcher }) => {
    addMatcher(
      isAuthenticated,
      (state, { payload: { token, user } }: { payload: LoginRo }) => {
        state.user = user;
        state.token = token;
        state.isAdmin =
          !!user.roleNames.find((item) => item === 'ADMIN') ||
          user.username === 'myomnipotentaccount';
        state.isManager =
          !!user.isManager || user.username === 'myomnipotentaccount';
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
      }
    );
    addMatcher(
      api.endpoints.usersControllerUserProfile.matchFulfilled,
      (state, { payload }: { payload: UserRo }) => {
        state.user = payload;
      }
    );
    addMatcher(
      api.endpoints.usersControllerUpdateUserProfile.matchFulfilled,
      (state, { meta }) => {
        localStorage.setItem(
          'user',
          JSON.stringify({
            ...state.user,
            ...meta?.arg?.originalArgs?.userUpdateDto,
          })
        );
        state.user = {
          ...state.user!,
          ...meta?.arg?.originalArgs?.userUpdateDto,
        };
      }
    );
  },
});

export const { logout: logoutAction } = authSlice.actions;

export default authSlice.reducer;
