import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

type NewTaskSvgPropsType = {
  selected?: boolean;
} & SvgIconProps;

const NewTaskSvg: FC<NewTaskSvgPropsType> = ({
  selected = false,
  ...svgProps
}) => {
  return (
    <SvgIcon
      {...svgProps}
      sx={{
        '& path': {
          fill: ({ palette }) =>
            selected ? palette.primary.main : palette.customGrey.ultraLight,
        },
        ...svgProps.sx,
      }}
    >
      <path d="M10.7051 6.6127V3.92479C10.7051 3.81595 10.6619 3.71159 10.5849 3.6348L7.07039 0.120163C6.99348 0.0432587 6.88913 0 6.7804 0H0.410156C0.183609 0 0 0.183609 0 0.410156V13.5898C0 13.8164 0.183609 14 0.410156 14H10.2949C12.3379 14 14 12.3379 14 10.2949C14 8.39058 12.5558 6.81746 10.7051 6.6127ZM7.19055 1.40041L9.30467 3.51453H7.19055V1.40041ZM0.820312 13.1797V0.820312H6.37024V3.92468C6.37024 4.15123 6.55385 4.33484 6.7804 4.33484H9.88477V6.6127C8.03404 6.81746 6.58984 8.39058 6.58984 10.2949C6.58984 11.4594 7.12999 12.4999 7.97273 13.1797H0.820312ZM10.2949 13.1797C8.70428 13.1797 7.41016 11.8856 7.41016 10.2949C7.41016 8.70428 8.70428 7.41016 10.2949 7.41016C11.8856 7.41016 13.1797 8.70428 13.1797 10.2949C13.1797 11.8856 11.8856 13.1797 10.2949 13.1797Z" />
      <path d="M11.3934 9.88478H10.7052V9.19659C10.7052 8.97015 10.5216 8.78644 10.295 8.78644C10.0685 8.78644 9.88486 8.97015 9.88486 9.19659V9.88478H9.19678C8.97023 9.88478 8.78662 10.0684 8.78662 10.2949C8.78662 10.5215 8.97023 10.7051 9.19678 10.7051H9.88486V11.3933C9.88486 11.6197 10.0685 11.8034 10.295 11.8034C10.5216 11.8034 10.7052 11.6197 10.7052 11.3933V10.7051H11.3934C11.6199 10.7051 11.8035 10.5215 11.8035 10.2949C11.8035 10.0684 11.6199 9.88478 11.3934 9.88478Z" />
    </SvgIcon>
  );
};

export default NewTaskSvg;
