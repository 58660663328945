import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

type NewFileSvgPropsType = {
  selected?: boolean;
} & SvgIconProps;

const NewFileSvg: FC<NewFileSvgPropsType> = ({
  selected = false,
  ...svgProps
}) => {
  return (
    <SvgIcon
      {...svgProps}
      sx={{
        '& path': {
          fill: ({ palette }) =>
            selected ? palette.primary.main : palette.customGrey.ultraLight,
        },
        ...svgProps.sx,
      }}
    >
      <path d="M12.8335 7.58331C12.6788 7.58331 12.5304 7.64477 12.421 7.75417C12.3116 7.86356 12.2502 8.01194 12.2502 8.16665V10.6242C12.2497 11.0553 12.0783 11.4685 11.7735 11.7733C11.4687 12.0781 11.0555 12.2495 10.6244 12.25H3.37592C2.94488 12.2495 2.53163 12.0781 2.22685 11.7733C1.92206 11.4685 1.75063 11.0553 1.75016 10.6242V8.16665C1.75016 8.01194 1.68871 7.86356 1.57931 7.75417C1.46991 7.64477 1.32154 7.58331 1.16683 7.58331C1.01212 7.58331 0.863747 7.64477 0.754351 7.75417C0.644954 7.86356 0.583496 8.01194 0.583496 8.16665V10.6242C0.584268 11.3646 0.878717 12.0744 1.40223 12.5979C1.92574 13.1214 2.63556 13.4159 3.37592 13.4166H10.6244C11.3648 13.4159 12.0746 13.1214 12.5981 12.5979C13.1216 12.0744 13.4161 11.3646 13.4168 10.6242V8.16665C13.4168 8.01194 13.3554 7.86356 13.246 7.75417C13.1366 7.64477 12.9882 7.58331 12.8335 7.58331Z" />
      <path d="M3.91241 5.07906L6.41666 2.57481V9.91663C6.41666 10.0713 6.47812 10.2197 6.58752 10.3291C6.69691 10.4385 6.84529 10.5 7 10.5C7.15471 10.5 7.30308 10.4385 7.41248 10.3291C7.52187 10.2197 7.58333 10.0713 7.58333 9.91663V2.57481L10.0876 5.07906C10.1976 5.18531 10.345 5.24411 10.4979 5.24278C10.6508 5.24145 10.7972 5.1801 10.9053 5.07195C11.0135 4.9638 11.0748 4.81749 11.0761 4.66454C11.0775 4.51159 11.0187 4.36424 10.9124 4.25422L7.41241 0.754227C7.30302 0.644869 7.15468 0.583435 7 0.583435C6.84532 0.583435 6.69697 0.644869 6.58758 0.754227L3.08758 4.25422C2.98132 4.36424 2.92252 4.51159 2.92385 4.66454C2.92518 4.81749 2.98653 4.9638 3.09468 5.07195C3.20284 5.1801 3.34914 5.24145 3.50209 5.24278C3.65504 5.24411 3.80239 5.18531 3.91241 5.07906Z" />
    </SvgIcon>
  );
};

export default NewFileSvg;
