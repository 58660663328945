import { FC } from 'react';
import { shallowEqual } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from 'src/app/reduxCustomHooks';

const PrivateRoute: FC = () => {
  const token = useAppSelector((state) => state.auth?.token, shallowEqual);

  return token ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
