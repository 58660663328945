import { FC, Dispatch, SetStateAction } from 'react';
import {
  IconButton,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { useTheme } from '@mui/system';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';
import { convertToCamelCase } from 'src/utils/commonUtils';
import BreadCrumbs from './BreadCrumbs';
// import NotificationCenter from './NotificationCenter';
// import { SearchBar } from './SearchBar';

const correctPath = (path: string) => {
  let result = path.replaceAll('/', '');
  if (result === '') {
    result = 'home';
  } else {
    result = convertToCamelCase(path.replaceAll('/', ' '));
  }
  return result;
};

type NavbarPropsType = {
  setOpenSandwichMenu: Dispatch<SetStateAction<boolean>>;
};

const Navbar: FC<NavbarPropsType> = ({ setOpenSandwichMenu }) => {
  const theme = useTheme(),
    isLg = useMediaQuery(theme.breakpoints.up('md')),
    { pathname } = useLocation(),
    { t } = useTranslation();

  return (
    <Toolbar sx={{ display: 'inline-block' }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ pt: { xs: 1, md: 2 }, pb: { xs: 1, sm: 0 } }}
      >
        <Stack direction="row" alignItems="center" columnGap={1}>
          {!isLg && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setOpenSandwichMenu((preState) => !preState)}
            >
              <MenuIcon color="primary" fontSize="large" />
            </IconButton>
          )}
          <Typography
            fontSize={{ xs: 25, md: 32 }}
            fontWeight={700}
            color="white"
            sx={{ textTransform: 'capitalize' }}
          >
            {t(correctPath(pathname))}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" columnGap={2}>
          {/* <SearchBar /> */}
          {/* <NotificationCenter /> */}
          <LanguageSwitcher />
        </Stack>
      </Stack>
      <BreadCrumbs />
    </Toolbar>
  );
};

export default Navbar;
