import { createSlice } from '@reduxjs/toolkit';
import { UserRo } from '../services/api.generated';

type employeeSliceType = {
  selectedEmployee: UserRo | null;
};

const initialState: employeeSliceType = {
  selectedEmployee: null,
};

const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setSelectedEmployee: (
      state,
      { payload }: { payload: employeeSliceType['selectedEmployee'] }
    ) => {
      state.selectedEmployee = payload;
    },
  },
});

export const { setSelectedEmployee: setSelectedEmployeeAction } =
  employeeSlice.actions;

export default employeeSlice.reducer;
