import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useLocation } from 'react-router';
import {
  listSubItemType,
  selectedItemDefinerType,
} from 'src/types/listItemsTypes';
import { useAppDispatch } from 'src/app/reduxCustomHooks';
import { setSelectedEmployeeAction } from 'src/app/slices/employeeSlice';
import ListSubItems from './ListSubItems';
import CustomListItemButton from 'src/components/atoms/CustomListItemButton';

const selectedItemDefiner = ({
  pathname,
  path,
  extraPath,
  isEditPage,
}: selectedItemDefinerType) => {
  let result: boolean | undefined;
  if (isEditPage && path === '/') {
    result = true;
  } else {
    result = pathname === path || extraPath?.includes(pathname);
  }
  return result;
};

type ListItemsPropsType = {
  isOpen: boolean;
  setOpenSandwichMenu: Dispatch<SetStateAction<boolean>>;
  list: {
    title: string;
    path: string;
    icon: any;
    subPages?: listSubItemType[];
  }[];
};

const ListItems: FC<ListItemsPropsType> = ({
  list,
  isOpen,
  setOpenSandwichMenu,
}) => {
  const [hoveredItemIndex, setHoveredItemIndex] = useState<null | number>(null);
  const [openItemIndex, setOpenItemIndex] = useState<null | number>(null);

  useEffect(() => {
    if (!hoveredItemIndex) {
      setOpenItemIndex(null);
    }
    const timer = setTimeout(() => {
      setOpenItemIndex(hoveredItemIndex);
    }, 200);
    return () => clearInterval(timer);
  }, [hoveredItemIndex]);

  const dispatch = useAppDispatch(),
    { pathname } = useLocation(),
    theme = useTheme();

  const isEditPage = pathname.includes('edit');

  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  const resetSelectedEmployeeProfile = useCallback(
    (path: string) => {
      if (path.includes('profile')) {
        dispatch(setSelectedEmployeeAction(null));
      }
      !isMd && setOpenSandwichMenu(false);
    },
    [dispatch, isMd, setOpenSandwichMenu]
  );

  return (
    <>
      {list.map(({ title, path, icon, subPages }, index) => {
        const extraPath = subPages?.map(({ path }) => path);
        const isSelected = selectedItemDefiner({
          pathname,
          path,
          extraPath,
          isEditPage,
        });

        const isItemHovered = openItemIndex === index;

        return (
          <Box
            key={index}
            onMouseEnter={() => setHoveredItemIndex(index)}
            onMouseLeave={() => setHoveredItemIndex(null)}
          >
            <CustomListItemButton
              to={path}
              sx={{
                px: 3.7,
                my: 1,
                mx: 'auto',
                transform: '300ms',
              }}
              onClick={() => resetSelectedEmployeeProfile(path)}
            >
              <ListItemIcon
                sx={{
                  minWidth: isOpen || !isMd ? 35 : undefined,
                  transition: '300ms',
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText
                primary={title}
                primaryTypographyProps={{
                  color: 'white',
                  fontWeight: isSelected ? 'bold' : undefined,
                  fontSize: '18px',
                }}
              />
            </CustomListItemButton>
            {subPages && (
              <div
                className="sub-list-item"
                style={{
                  transformOrigin: 'center top',
                  transform: isItemHovered || !isMd ? 'scaleY(1)' : 'scaleY(0)',
                  opacity: isItemHovered || !isMd ? 1 : 0,
                  transition: '300ms',
                  margin: `auto ${theme.spacing(5)}`,
                  height: isItemHovered || !isMd ? 'auto' : 0,
                }}
              >
                <ListSubItems subItems={subPages} />
              </div>
            )}
          </Box>
        );
      })}
    </>
  );
};

export default ListItems;
