import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { logoutAction } from '../slices/authSlice';
import { RootStateType } from '../store';
import i18n from 'src/i18n';

let baseUrl =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_DEVELOPMENT_BASE_URL
    : process.env.REACT_APP_PRODUCTION_BASE_URL;

if (!baseUrl || baseUrl === 'auto') {
  baseUrl = window.location.origin;
}

export const baseQuery: BaseQueryFn<
  {
    url: string;
    method?: AxiosRequestConfig['method'];
    body?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
    headers?: AxiosRequestConfig['headers'];
    abortController?: AbortController;
    onUploadProgress?: AxiosRequestConfig['onUploadProgress'];
  },
  unknown,
  unknown
> = async (
  {
    url,
    method = 'GET',
    body,
    params,
    headers,
    abortController,
    onUploadProgress,
  },
  { getState, dispatch }
) => {
  try {
    const { auth } = getState() as RootStateType;

    const result = await axios({
      url: baseUrl + url,
      method,
      data: body,
      params,
      headers: {
        ...headers,
        ...(auth?.token && { authorization: `Bearer ${auth.token}` }),
      },
      ...(abortController && { signal: abortController.signal }),
      onUploadProgress,
    });
    return { data: result.data };
  } catch (axiosError) {
    const e = axiosError as AxiosError;
    const error = {
      status: e.response?.status,
      data: (e.response?.data || e.message) as any,
    };

    console.log('rtk query error handler => ', error);
    if (error?.data === 'canceled') {
      return { error };
    }
    if (error.status === 401) {
      dispatch(logoutAction());
    } else if (error.status !== 404) {
      const errorMessage = () => {
        let result = '';
        const lang = i18n.language;
        if (lang === 'en') {
          result = 'Something went wrong, please try again later.';
        }
        if (lang === 'nl') {
          result = 'Er is iets misgegaan, probeer het later opnieuw.';
        }
        return result;
      };

      console.log(
        error.data?.message
          ? error.status
            ? error.data?.message + ` (status code: ${error.status})`
            : error.data?.message
          : errorMessage()
      );
    }

    return { error };
  }
};
