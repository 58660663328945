import { indexType } from '../types/generalTypes';
import { convertToStringRegex } from './regexUtils';

export const removeElementFromArray = (array: any[], element: any) => {
  let newArray = [...array];
  const index = array.indexOf(element);
  if (index > -1) {
    newArray.splice(index, 1);
  }
  return newArray;
};

export const twoDigitNumber = (time: string) => {
  return ('0' + time).slice(-2);
};

export const clockFormat = (time: number): string => {
  const minutes = Math.floor(time / 60).toString();
  const seconds = (time % 60).toString();
  return `${persianConverter(twoDigitNumber(minutes))}:${persianConverter(
    twoDigitNumber(seconds)
  )}`;
};

export const persianConverter = (
  number: indexType,
  toEnglish: boolean = false
): string => {
  if (typeof number === 'number') {
    return number.toString();
  }
  const persianNumbers = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
  let convertedNumber = '';
  for (let i = 0; i < number.length; i++) {
    if (toEnglish) {
      const convertedChar = persianNumbers.indexOf(number[i]);
      convertedNumber += convertedChar === -1 ? number[i] : convertedChar;
    } else convertedNumber += persianNumbers[parseInt(number[i])];
  }
  return convertedNumber;
};

export const IBANRegex = () =>
  /^(\b(I|i)(R|r)[0-9]{2}(?:[ -]?[0-9]{4}){5}(?!(?:[ -]?[0-9]){3})(?:[ -]?[0-9]{2})?\b)$|^$/;

// export const timeConverter: (
//   date: indexType,
//   language?: languageType
// ) => string = (date, language) => {
//   let newTime;
//   if (typeof date === 'string') {
//     newTime = parseInt(date);
//   }

//   const localMoment = jMoment(newTime);

//   if (language && language === 'en') {
//     localMoment.locale('en');
//   } else {
//     localMoment.locale('fa');
//   }

//   return localMoment.format('l');
// };

export const compareTimes = (array: any, keyOfDate?: any) => {
  let newArray = [];
  newArray = array.sort((a: any, b: any) => {
    return (new Date(b[keyOfDate]) as any) - (new Date(a[keyOfDate]) as any);
  });
  return newArray;
};

export const sortArrayByFirstLetter: (array: any[]) => any[] = (array) => {
  return array.slice(0).sort((a, b) => -b.name.localeCompare(a.name));
};

export const checkEqualityObjectInArray = (
  object: any,
  prop: string,
  list: Array<any>
) => {
  let x = false;
  list.map((item) => (item[prop] === object[prop] ? (x = true) : undefined));
  return x;
};

const convertToString = (input: string) => {
  if (input) {
    if (typeof input === 'string') {
      return input;
    }
    return String(input);
  }
  return '';
};

// convert string to words
const toWords = (input: string) => {
  input = convertToString(input);
  return input.match(convertToStringRegex);
};

// convert the input array to camel case
const toCamelCase = (inputArray: string[]) => {
  let result = '';
  for (let i = 0, len = inputArray.length; i < len; i++) {
    const currentStr = inputArray[i];
    let tempStr = currentStr.toLowerCase();
    if (i !== 0) {
      // convert first letter to upper case (the word is in lowercase)
      tempStr = tempStr.substr(0, 1).toUpperCase() + tempStr.substr(1);
    }
    result += tempStr;
  }
  return result;
};

export const convertToCamelCase = (input: string) => {
  const words = toWords(input) as RegExpMatchArray;
  return toCamelCase(words);
};

type convertArrayOfStringToNameValuePropsType = {
  array: string[];
  translateFunc?: (value: string) => any;
};
export const convertArrayOfStringToNameValue = ({
  array,
  translateFunc,
}: convertArrayOfStringToNameValuePropsType) => {
  const newArray = array.map((item) => ({
    name: translateFunc ? translateFunc(item) : item,
    value: item,
  }));
  return newArray;
};
